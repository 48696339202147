<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Plant'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="pabrik_id_label"
                  label="Pabrik :"
                  label-for="pabrik_id"
                >
                  <b-form-select
                    id="pabrik_id"
                    v-model="form.pabrik_id"
                    :options="pabrik"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nama_plant_label"
                  label="Nama Plant :"
                  label-for="nama_plant"
                >
                  <b-form-input
                    id="nama_plant"
                    v-model="$v.form.nama_plant.$model"
                    type="text"
                    placeholder="Enter Text..."
                    :state="validateState('nama_plant')"
                  ></b-form-input>

                  <b-form-invalid-feedback id="nama_plant_feedback">
                    Nama Plant Harus Terisi.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="file_label"
                  label="File :"
                  label-for="file"
                >
                  <b-form-file
                    id="file"
                    v-model="form.file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                </b-form-group>

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/master/plant')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapState } from 'vuex'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import $ from 'jquery'

export default {
  mixins: [validationMixin],
  name: 'addplant',
  data() {
    return {
      form: {
        pabrik_id: null,
        nama_plant: null,
        file: null,
      },
      pabrik: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 0,
      filter: null,
      filterOn: [],

      show: true,
      loading: false,
    }
  },
  validations: {
    form: {
      nama_plant: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
  },
  created() {
    var self = this

    ApiService.get('/master/pabrik/all')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var pabrik = data.data
          // self.pabrik = pabrik;
          for (let i = 0; i < pabrik.length; i++) {
            self.pabrik.push({
              value: pabrik[i].id_pabrik,
              text: pabrik[i].nama_pabrik,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Master', route: '/master/plant' },
      { title: 'Create Plant' },
    ])
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      // event.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }

      var self = this
      self.loading = true
      const pabrik_id = self.form.pabrik_id
      const nama_plant = self.$v.form.nama_plant.$model
      const file = self.form.file

      const dataku = new FormData()
      dataku.append('pabrik_id', pabrik_id)
      dataku.append('nama_plant', nama_plant)
      if (file) {
        dataku.append('file', file)
      }
      var urlApi = ApiService.urlApi()

      $.ajax({
        url: urlApi + '/master/plant/store',
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        headers: ApiService.headerAPi(),
        success: function(response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.push('/master/plant')
          }
        },
      })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
  },
}
</script>
